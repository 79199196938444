@import '../mixins/hover-focus';
@import '../mixins/background-variant';

// Fluz custom colors
$white: #ffffff;
$gray_1000: #3c4b64;

// Gray
$gray_100: #1a0000;
$gray_150: #221919;
$gray_200: #2b2624;
$gray_250: #2f2b29;
$gray_300: #373432;
$gray_350: #403d3b;
$gray_400: #514f4c;
$gray_450: #65635f;
$gray_500: #787571;
$gray_550: #989790;
$gray_600: #aeaea6;
$gray_650: #c0bfb7;
$gray_700: #d2d2d0;
$gray_700_backdrop: #d2d2d0f2;
$gray_750: #e3e3e3;
$gray_800: #eaeaea;
$gray_850: #efefef;
$gray_900: #f2f2f2;
$gray_950: #f6f6f6;

// Fluz butterfly colors
$green_100: #21ba4c;
$green_200: #01d054;
$green_300: #00d053;

$mint_100: #00b99b;
$mint_200: #5bb89a;
$mint_300: #a1e696;

$lime_100: #83da00;
$lime_200: #c3e605;
$lime_300: #c1dd6c;
$lime_400: #c7d08f;

$blue_100: #6184e7;
$blue_200: #73b5ff;
$blue_300: #82beff;
$blue_400: #8cbbd3;
$blue_500: #83c0ff;

$periwinkle_100: #a6b4f7;
$periwinkle_200: #a2abf9;
$periwinkle_300: #a4bfff;

$teal_100: #00a9bc;
$teal_200: #84d2df;

$yellow_100: #d5cc0a;
$yellow_200: #e5e230;
$yellow_300: #e2ca7b;
$yellow_400: #e6dd96;

$red_100: #ff0000;
$red_200: #ff7373;
$red_300: #e48c7b;
$red_400: #ec7e98;

$pink_100: #e47bac;
$pink_200: #ff77ab;
$pink_300: #fdb0f1;
$pink_400: #f8b2e9;
$pink_500: #f4d4e1;
$pink_600: #f7b4f5;

$peach_100: #ffb18c;
$peach_200: #f2c79a;

$rose_100: #fc8b8b;
$rose_200: #f7a6a6;
$rose_300: #fab6b6;

$orange_100: #fdc251;
$orange_200: #ffc600;

$brown_100: #d8a46a;

$taupe_100: #c4c1a6;

$purple_100: #ba80ff;
$purple_200: #c6afe1;
$purple_300: #e3d1df;
$purple_400: #bb8ede;

$gray_100: #adc1cd;
$gray_200: #c4c9cd;
$gray_300: #dfdfdf;
$gray_400: #50585f;
$gray_500: #aebbc3;
$gray_600: #73828d;
$gray_700: #e4eaee;
$gray_800: #636f83;
$gray_1050: #f8f8f8;

$black_100: #170100;
$black_150: #50585f;
$black_200: #7a8288;
$black_300: #92a2ab;
$black_350: #aebbc3;
$black_400: #e4eaee;
$black_450: #eff4f8;
$black_500: #f3f7f9;
$black_600: #f9fafb;
$natural_dark: #1a0000;
$natural_off_white: #fff9fe;
$powder: #e4d2e0;
