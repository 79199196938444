$toast-body-font-size: 17px;
$toast-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !default;
$toast-border-radius: 6px !default;
$toast-color: $white;
$toast-background-color: $gray_1000 !default;
$toast-border-color: $gray_1000 !default;
$toast-border-width: 1px !default;

$toast-header-background-color: $gray_1000 !default;
$toast-header-border-color: $gray_800 !default;
$toast-header-letter-spacing: 0.4px !default;
$toast-font-size: 18px !default;
