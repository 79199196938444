.btn {
  border-radius: 3px;
  font-family: Greed-SemiBold;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-size: 17px;
  letter-spacing: -0.25px;
  text-align: center;
  border-width: 0;
  color: $black_100;
  &:hover {
    opacity: 0.65;
  }
  &:active {
    opacity: 0.85;
  }
  &[disabled]:hover {
    color: $black_100;
    cursor: default;
  }
}

.btn-more-options {
  @extend .btn;
  border-width: 1.5px;
  border-color: $black_400;
  padding: 7.5px 12px;
  display: flex;
  border-radius: 100px;
  margin-top: 8px;
  align-items: center;
  font-size: 14px;

  [data-state='iframe'] & {
    background-color: $gray_850;
    border: none;
  }
}
.btn-hover-active-gray {
  &:hover {
    color: $gray_800;
  }
  &:active {
    color: $gray_800;
  }
}

.btn-hover-active-white {
  &:hover {
    color: $white;
  }
  &:active {
    color: $white;
  }
}

.btn-header-background {
  min-width: 0;
  min-height: 0;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 1.875em;
  height: 1.875em;
  border-radius: 50px;
  background-color: $black_500;
  @extend .btn-hover-active-gray;

  [data-state='iframe'] & {
    background-color: $gray_850;
  }
}

.btn-header-link {
  width: 1em;
  height: 1em;
  padding: 0;
}

.btn-registration-method {
  @extend .btn;
  width: 100%;
  border-radius: 50px;
  background-color: $black_500;
  color: $black_100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  min-width: 15em;
  font-size: 19px;
  padding-top: 14px;
  padding-bottom: 15px;
  border: 1px solid $black_100;
  min-height: 30px;

  [data-state='iframe'] & {
    background-color: $gray_850;
    border: none;
  }
}

.btn-form-submit-parent {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.btn-form-submit-child {
  position: absolute;
  z-index: 1;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.btn-form-submit {
  @extend .btn;
  background: linear-gradient(90deg, $orange_100 62%, $pink_600 97%); //hor_dual_6
  width: 100%;
  border-radius: 50px;
  margin-top: 0.9rem; // Must be the same value as .btn-form-loading
  margin-bottom: 0.9rem; // Must be the same value as .btn-form-loading
  font-size: 1.5rem;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 22px;
}

.btn-complete-later {
  @extend .btn-form-submit;
  background: $black_500;
  margin-top: 0;
  @media screen and (min-width: 600px) {
    padding-top: 0rem;
    padding-bottom: 0rem;
    height: 30px;
    font-size: 16px;
  }
  color: $black_100;
}
.btn-download-fluz {
  @extend .btn-form-submit;
  background: $black_100;
  color: $black_500;
}
.btn-email-link {
  @extend .btn-form-submit;
  background: black;
  color: $black_600;
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 0;
}

.btn-form-submit-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btn-form-loading {
  margin-top: 0.9rem; // Must be the same value as .btn-form-submit
  margin-bottom: 0.9rem; // Must be the same value as .btn-form-submit
}

.more-options-loading {
  height: 24px;
  width: 24px;
}

.btn-email-domain {
  font-family: Area-Bold;
  border-radius: 50px;
  color: $gray_600;
  border: solid 1px $gray_700;
  padding: 0.75em;
  font-size: 0.8rem;
  margin-right: 0.5em;
  margin-top: 0.5em;
  text-align: center;
}

.btn-pincode-more-options {
  color: inherit;
  font-family: inherit;
  font-size: 0.9em;
  padding: 0;
  text-decoration: underline;
  display: flex;
  align-items: center;
  width: 8.4em;
}

/** More options view */
.more-options-button-background {
  min-width: 0;
  min-height: 0;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-color: $black_500;
}

.more-options-text {
  color: $black_100;
  display: flex;
  font-family: Greed-SemiBold;
  font-size: 1em;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 0;
  width: 100%;
}

.more-options-arrow {
  width: 12px;
  height: 12px;
}

.more-options-icon {
  width: 16px;
  height: 16px;
}

@media screen and (min-width: 600px) {
  .btn-form-submit-parent {
    height: inherit;
  }

  .btn-form-submit-child {
    position: inherit;
    width: 100%;
  }

  .btn-form-submit-col {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 600px) {
  //to fix the more option hover issue
  .extra-option-button:hover {
    opacity: 1;
    color: $black_100;
  }
}

.cta {
  p {
    font-family: Greed-SemiBold;
    font-size: 14px;
    text-align: center;
    color: $black_150;
    margin-top: 11px;
    margin-bottom: 11px;
  }
}
