// If you want to override variables do it here
@import './variables/variables';
@import './variables/colors';
@import './variables/fonts';
@import './variables/toaster';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';
@import '~bootstrap/scss/bootstrap';

//mixins
@import './mixins/background-variant';
@import './mixins/hover-focus';

// If you want to add something do it here
@import './styles/typo.style';
@import './styles/card.style';
@import './styles/buttons.style';
@import './styles/toaster.style';
@import './styles/tooltip.style';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
