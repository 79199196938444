.tooltip-inner {
  background: linear-gradient(black, black) padding-box,
    linear-gradient(90deg, #d5cc0a 45.26%, #f7a6a6 64.05%, #c7d08f 79.5%, #e3d1df 99.7%) border-box;
  border-radius: var(--spacing-06, 12px);
  border: 2px solid transparent;
}

.tooltip-text {
  color: $white;
  font-family: $font-family-area-extra-bold;
  font-size: $font-size-12;
  line-height: $line-height-18;
}

.tooltip#staging-tooltip {
  top: 8px !important;
}
