@font-face {
  font-family: 'Area-Black';
  src: local('Area-Black'), url(../../assets/fonts/fluz-default-fonts/Area-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Area-BlackItalic';
  src: local('Area-BlackItalic'), url(../../assets/fonts/fluz-default-fonts/Area-BlackItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Area-Bold';
  src: local('Area-Bold'), url(../../assets/fonts/fluz-default-fonts/Area-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Area-BoldItalic';
  src: local('Area-BoldItalic'), url(../../assets/fonts/fluz-default-fonts/Area-BoldItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Area-Extrabold';
  src: local('Area-Extrabold'), url(../../assets/fonts/fluz-default-fonts/Area-Extrabold.otf) format('opentype');
}

@font-face {
  font-family: 'Area-Extrabolditalic';
  src: local('Area-ExtraboldItalic'),
    url(../../assets/fonts/fluz-default-fonts/Area-ExtraboldItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Area-Semibold';
  src: local('Area-Semibold'), url(../../assets/fonts/fluz-default-fonts/Area-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'Area-SemiboldItalic';
  src: local('Area-SemiboldItalic'),
    url(../../assets/fonts/fluz-default-fonts/Area-SemiboldItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Greed-CondensedSemiBold';
  src: local('Greed-CondensedSemiBold'),
    url(../../assets/fonts/fluz-default-fonts/Greed-CondensedSemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Greed-SemiBold';
  src: local('Greed-SemiBold'), url(../../assets/fonts/fluz-default-fonts/Greed-SemiBold.ttf) format('truetype');
}
