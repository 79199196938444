.toast {
  max-width: 100% !important;
  width: 100% !important;
  box-shadow: 0px 32px 64px -12px rgba(20, 20, 20, 0.14);
  background: linear-gradient(white, white) padding-box, linear-gradient(90deg, #fdc251, #fdb0f1) border-box;
  border-radius: var(--spacing-06, 12px);
  border: 3px solid transparent;
}

.toast-header {
  font-family: Greed-CondensedSemiBold;
  font-size: $toast-font-size;
  letter-spacing: $toast-header-letter-spacing;
  justify-content: space-between;
  .close {
    width: 10px;
    height: 10px;
    border-radius: 0.5px;
    background-color: transparent;
    color: $black_100 !important;
    padding-bottom: 5px;
    font-weight: 100;
    line-height: 0.5;
    margin-bottom: auto;
    &:hover {
      color: $black_100;
    }
    &:focus {
      outline: 0;
    }
  }
  border-bottom: none;
}
.toast-header-danger {
  @extend .toast-header;
  font-family: Greed-CondensedSemiBold;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: $danger;
}

.toast-header-success {
  @extend .toast-header;
  color: $success;
}
.toast-header-warning {
  @extend .toast-header;
  color: $warning;
}
.toast-header-info {
  @extend .toast-header;
  color: $info;
}
.toast-header-custom {
  @extend .toast-header;
  color: $black_100 !important;
  background-color: $white !important;
}

.toast-body {
  font-family: Area-Semibold;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.24px;
  color: $black_100;
  background-color: $white;
  padding-top: 0.25em;
}

.toast-info {
  border: 2px solid transparent;
  background: linear-gradient(89.74deg, #82beff 0%, #a6b4f7 47.21%, #5bb89a 73.75%, #21ba4c 91.26%) border-box;
}
